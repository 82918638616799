import React from "react";

import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";

import Button from "../../components/Button/Button";
import DetailPageHeader from "../../components/DetailPageHeader/DetailPageHeader";
import QuickFacts from "../../components/QuickFacts/QuickFacts";
import QuickFactsItem from "../../components/QuickFactsItem/QuickFactsItem";
import QuickFactsLink from "../../components/QuickFactsLink/QuickFactsLink";
import Sharing from "../../components/Sharing/Sharing";
import { ExternalLinkIcon24 } from "../../icons";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";

const ProjectPage = ({ data: { page } }) => {
  const { t } = useTranslation();

  return (
    <MainLayout breadcrumb={<Breadcrumb id={page.id} />}>
      <DetailPageHeader
        headline={page.title.trim() + (page.year ? `, ${page.year}` : "")}
        image={page.images.images
          && page.images.images.length > 0
          && page.images.images[0].asset && (
            <SanityImage
              {...page.images.images[0]}
              width={800}
              className="w-full"
            />
        )}
        sharing={(
          <Sharing
            shareTitle={page.title}
            title={t("shareProject")}
          />
        )}
        type={page.type}
      />
      <div className="grid grid-cols-12 mx-18">
        <div className="col-span-12 order-2 lg:order-1 lg:col-span-9">
          <div className="col-span-12 order-2 lg:order-1 lg:col-span-9">
            {page._rawDescription
              && (
                <div className="grid grid-cols-12 lg:grid-cols-9">
                  <div className="col-span-12 lg:col-span-7 lg:col-start-2 text-h3-mobile lg:text-h3-desktop uppercase">
                    {t("aboutTheProject")}
                  </div>
                  <div className="col-span-12 lg:col-span-8 text-body1-mobile lg:text-body1-desktop mt-24 lg:mt-42">
                    <BlockContent
                      blocks={page._rawDescription}
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="col-span-12 order-1 mb-40 lg:order-2 lg:col-span-3 lg:mb-0">
          <QuickFacts>
            <QuickFactsItem title={t("kind")}>
              {t(page.type)}
            </QuickFactsItem>
            {page.cooperationPartner && (
              <QuickFactsItem title={t("cooperationPartner")}>
                {page.cooperationPartner}
              </QuickFactsItem>
            )}
            {page.sponsor && (
              <QuickFactsItem title={t("SponsoredBy")}>
                {page.sponsor}
              </QuickFactsItem>
            )}
            {page.furtherLinks.length > 0 && (
              <QuickFactsItem title={t("furtherLinks")}>
                {page.furtherLinks?.map((link) => (
                  <QuickFactsLink data={link} />
                ))}
              </QuickFactsItem>
            )}
          </QuickFacts>
        </div>
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: sanityProject(id: {eq: $id}) {
      id
      title
      type
      cooperationPartner
      sponsor
      furtherLinks {
        title
        linkType
        href
        _rawInternalLink
        file {
          asset {
            assetId
            path
            url
          }
        }
      }
      _rawDescription
      images {
        images {
          ...ImageWithPreview
          alt
        }
      }
      Metadata {
        ...MetaData
      }
    }
  }
`;

ProjectPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default ProjectPage;
